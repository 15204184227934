import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  LinkOutlined,
  EditOutlined,
  BarChartOutlined,
  DesktopOutlined,
  MoreOutlined,
  UndoOutlined 
} from "@ant-design/icons";
import { Button ,Menu} from "antd";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { graphQL_shema } from "../page/utils/graphql";
import { useMutation } from "@apollo/react-hooks";
import { endEventFlow, endEventRec, startEventRec } from "../redux/actions";
import { useDispatch } from "react-redux";
import MenuMore from "./MenuMore";
import { GraphQLFetchData } from "../page/utils/graphQLFetchData";
const UseActionMenu = ({
  handleDelete,
  record,
  showModaltabletoaction,
  takeIdVideoTabletoaction,
  refetchEvents,
  GetTrashedEvents,
  handleRestore
}) => {
  const history = useHistory();
  const { event_module } = useSelector(
    (state) => state.ReducerUserData.modules
  );
  const dispatch = useDispatch();

  const { t } = useTranslation(); // translation
  const [startEvent] = useMutation(graphQL_shema().START_EVENT, {
    onCompleted: (data) => {
      if (data.updateEventOrganizerStatus.code ==="200"){
        dispatch(startEventRec(record));
      }
    },
  });
  const { restoreMutation} = GraphQLFetchData();
  const searchParams = new URLSearchParams(window.location.search);
  const evenement = searchParams.get("filter"); // language params
  const [endEvent] = useMutation(graphQL_shema().START_EVENT, {
    onCompleted: (data) => {
      if (data.updateEventOrganizerStatus.code ==="200"){
        dispatch(endEventRec(record));
        dispatch(endEventFlow(record));

      }
    },
  });
  const handleDiffusion = () => {
    switch (record.status) {
      case -1:
        startEvent({
          variables: {
            id: record.id,
            status: 1,
          },
        });
        break;
      case 1:
        endEvent({
          variables: {
            id: record.id,
            status: 0,
          },
        });
        break;

      default:
        startEvent({
          variables: {
            id: record.id,
            status: 1,
          },
        });
        break;
    }
  };

  const handleMenuClick = () => {
    history.push(`/events/editevent/${record.id}`, {
      record: record,
      action: "update",
    });

    window.parent.postMessage(
      {
        info: "event_edit",
        value: "edit",
      },
      "*"
    );
  };
  // fonction pour afficher le modal d'embed code
  const handleCode = () => {
    showModaltabletoaction(true, record);
    takeIdVideoTabletoaction(record.idVideo);
  };
  // statistic Route
  const handleStatistic = () => {
    window.parent.postMessage(
      {
        info: "showEventStat",
        value: record.id,
      },
      "*"
    );
  };
 
  
 
  const menuAction = (
    <div className={"MenuAction"}>

  <>
        <Button
          key="diffusion"
          onClick={handleDiffusion}
          disabled={record.status===0 || record.isAuto}
          style={{
            color:
              record.status === 1 ? "green" : record.status === 0 ? "red" : "",
          }}
          icon={<img src={record.status === 1 ? "/assets/playGreen.svg" : record.status === 0 ? "/assets/stopRed.svg" : "/assets/playBlue.svg"}/>}
        />

        <span className="EditAction">{t("Action.diffuser")}</span>
      </>
   

      {event_module?.event_post_edit_action?.authorized && (
        <>
             <Button
            key="edit"
            has-permission="event_post_edit"
            onClick={handleMenuClick}
          icon={<EditOutlined />}
        />
  
          <span className="EditAction">{t("Action.modifier")}</span>
        </>
      )}
      {event_module?.event_get_export_action?.authorized ? (
        <>
               <Button
            key="edit"
            has-permission="event_get_export"
            onClick={() => handleCode(record)}
          icon={<LinkOutlined />}
        />

          <span className="LinkAction">{t("Action.lienExport")}</span>
        </>
      ) : null}

      {event_module?.event_delete_event_live_action?.authorized ? (
        <>
                  <Button
            has-permission="event_delete_event_live"
            onClick={() => handleDelete(record)}
          icon={<DeleteOutlined />}
        />
          <span className="DeleteAction">{t("Action.Supprimer")}</span>
        </>
      ) : null}

       {event_module && evenement === "trash" ? (
        <>
                  <Button
            has-permission="event_delete_event_live"
            onClick={() => handleRestore(record)}
          icon={<UndoOutlined />}
        />
          <span className="DeleteAction">{t("Action.Supprimer")}</span>
        </>
      ) : null}
      {event_module?.event_stats_no_api_action?.authorized ? (
        <>
                <Button
            has-permission="event_stats_no_api"
            onClick={handleStatistic}
          icon={<BarChartOutlined />}
        />
   
          <span className="StatisticAction">{t("Action.Statistiques")}</span>
        </>
      ) : null}
    </div>
  );
  const size = useMediaQuery("(max-width: 600px)");
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const handleOpen = (e) => {
		setAnchor(e.target);
	};
	const handleClose = () => setAnchor(null);

  return (
    <>
      <div className="div_tooltip_action" id="content-desktop">

        {size ? 
          <>
          <Button
          className="moreIcon"
          onClick={handleOpen}
          icon={<MoreOutlined />}/>
          <MenuMore anchor={anchor} open={open} close={handleClose} menu={menuAction}/>
          </> 
          :  
          menuAction
         }
      </div>
    </>
  );
};

export default UseActionMenu;
