import { useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { GraphQLFetchData } from "../utils/graphQLFetchData";
import { useDispatch } from "react-redux";


export const Uploadfile = ({ initialfilelist, initialuploadFile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uploadToS3 } = GraphQLFetchData();
  const [uploadFile, setFileUpload] = useState(initialuploadFile);
  const [fileList, setFileList] = useState(initialfilelist);
  const [cropper,setCropper] = useState(null)
  const ALLOWED_EXTENSIONS = ["jpeg", "png", "apng", "avif", "gif", "svg", "webp"];
  const MAX_THUMBNAIL_SIZE_MB = 5;
  const ACCEPTED_DIMENSIONS = [
    { width: 720, height: 480 },
    { width: 1280, height: 720 },
    { width: 1920, height: 1080 },
  ];

  const beforeUpload = async (file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const fileSizeMB = file.size / (1024 * 1024);

    const isValidExtension = fileExtension && ALLOWED_EXTENSIONS.includes(fileExtension);
    const isValidSize = fileSizeMB <= MAX_THUMBNAIL_SIZE_MB;

    if (!isValidExtension) {
      message.error(t("showEvent.extensions_accepted_message"));
      return false;
    }

    if (!isValidSize) {
      message.error(t("showEvent.maximum_thumbnail_size"));
      return false;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      img.onload = function () {
        const width = img.width;
        const height = img.height;

        const acceptedDimensions = ACCEPTED_DIMENSIONS.some(
          (dimension) => width === dimension.width && height === dimension.height
        );
        if (!acceptedDimensions) {
          message.error(t("showEvent.dimension_accepted_message"));
          resolve(false);
        } else {
          resolve(true);
        }
      };
      img.onerror = reject;
    });
  };

  const onChangeFile = async (info) => {
    try {
      const file = info.fileList[0]?.originFileObj;

      if (info.fileList.length === 0) {
        dispatch({ type: "UPLOAD_LOGO_URL", payload: "" });
        dispatch({ type: "UPLOAD_LOGO_THEME", payload: "" });
      }

      setFileList(info.fileList);
      setFileUpload(file);

      if (info.fileList.length ) {
        await uploadToS3({ variables: { file, subFolder: "events/vignettes/" } });
      }else {
        return setFileList([]);
      }

    } catch (error) {
      console.error("Error in onChangeFile:", error);
    }
  };
  const getCropData = async (e) => {
    e.preventDefault();
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const file = await fetch(croppedCanvas.toDataURL())
          .then((res) => res.blob())
          .then((blob) => new File([blob], "newAvatar.png", { type: "image/png" }));
        if (file) {
          await uploadToS3({ variables: { file, subFolder: "events/vignettes/" } });  
        }
      }
    }
  };

  
    
const onDelete = async ()=>{
  setFileUpload('')
  // setFileList([]);
  dispatch({ type: "UPLOAD_LOGO_URL", payload: "" });
  dispatch({ type: "UPLOAD_LOGO_THEME", payload: "" });
  dispatch({type:"SET-CROPPED",payload:true})
}
  return {
    onChangeFile,
    beforeUpload,
    onDelete,
    uploadFile,
    fileList,
    setCropper,
    getCropData
  };
};

Uploadfile.defaultProps = {
  initialfilelist: [],
  initialuploadFile: null,
};
