
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import 'moment-timezone';

export const useFormEvent = (callback, addPath, initialState, eventID, form, idUser, uploadFile, language, uploadFileUrl,languageUrl,inputYoutube,listLang) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [values, setValues] = useState(initialState);
    const [variables, setVariables] = useState({});
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const uploadLogoUrl = useSelector(state=>state.reducer.logo)
    const onChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })


    };
    const onChangeKeywords = (keywords) => {
        setValues({ ...values, "keyWords": keywords.join(" ") })
    };
    const handleLangSelect = (action) =>{
        if(action){
            setValues({ ...values, langs: action?.map(lang=>lang.value) })
        }
    }
    const onChange_select = (name, value, action) => {
        if (value) setValues({ ...values, [action.name]: action.value })
    }
    const onChange_datePicker = (value, action) => {
        setValues({ ...values, "startDate": moment(action) });
    }
    const onChange_dateTime_fin = (value, action) => {
        setValues({ ...values, "finalHour": moment(action, 'HH:mm') });
    }
    const onChange_dateTime_debut = (value, action) => {
        setValues({ ...values, "startHour": moment(action, 'HH:mm') });
        // Calculer l'heure de fin pour qu'elle soit supérieure à l'heure de début
        const endHour = moment(action, 'HH:mm').clone().add(2, 'hour');
        setEndTime(endHour);
        form.setFieldsValue({ endHour: endHour });
    };

    const onChange_checkbox = async (event,) => {
        let langs = [...values.langs]
        await setValues({ ...values, [event.target.value]: event.target.checked });
        !event.target.checked && langs.indexOf("autre") !== -1 && langs.splice(langs.indexOf("autre"), 1);
        event.target.value === "isVisibleOnInternet" && !event.target.checked && setValues({ ...values, isSecuredOnInternet: false, isVisibleOnInternet: false, passwordInternet: null })
        event.target.value === "isSecuredOnInternet" && !event.target.checked && setValues({ ...values, isSecuredOnInternet: false, passwordInternet: null })
        event.target.value === "isYoutube" && !event.target.checked && setValues({ ...values, isYoutube: false })

    }
    const onChange_choose_language = checkedValues => {
        setValues({ ...values, langs: checkedValues })
    };
    const disablePastDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    const startGetDisabledHours = (finalHour) => {
        let hours = [];
        if (values.startDate.format('YYYY-MM-DD') === moment().local().format('YYYY-MM-DD')) {
            for (let i = 0; i < moment().local().hour(); i++) {
                hours.push(i);
            }
        }
        else if (finalHour)
            for (let i = 23; i > finalHour.hour(); i--) {
                hours.push(i);
            }
        return hours;
    }

    const FinalGetDisabledHours = (startHour) => {
        const currentHour = moment().local();
        let hours = [];
        if (startHour && currentHour) {
            if (startHour.isSame(currentHour, 'hour')) {
                for (let i = 0; i < currentHour.hour(); i++) {
                    hours.push(i);
                }
            }else if (values.startDate.isSame(currentHour, 'day') && currentHour) { 
                for (let i = 0; i < currentHour.hour(); i++) {
                    hours.push(i);
                }
            }
        }
        return hours;
    };


    const startGetDisabledMinutes = (selectedHour) => {
        let minutes = [];
        if (values.startDate.format('YYYY-MM-DD') === moment().local().format('YYYY-MM-DD')) {
            if (selectedHour === moment().local().hour()) {
                for (let i = 0; i < moment().minute(); i++) {
                    minutes.push(i);
                }
            }
        }
        else if (values.finalHour && selectedHour === values.finalHour.hour())
            for (let i = 59; i > (values.finalHour.minutes()) - 1; i--) {
                minutes.push(i);
            }
        return minutes;
    }

    const finalGetDisabledMinutes = (selectedHour) => {
        let minutes = [];

        if (values.startHour && selectedHour === values.startHour.hour()) {
            for (let i = 0; i <= values.startHour.minute(); i++) {
                minutes.push(i);
            }
        }

        return minutes;
    };



    const handleOthersCheckbox = async (langs) => {
        if( !values.forbideanlanguage){
            await langs.indexOf("autre") !== -1  && langs.splice(langs.indexOf("autre"), 1);
        }else {
            await langs.indexOf("autre") !== -1  && langs.splice(langs.indexOf("autre"), 1, values.forbideanlanguage);
        }

        await setValues({...values,langs: langs, forbideanlanguage: ""});
    }
    const convertDateToUtcFormat = (date,time) => { 
        const convertedDateLocal = moment(`${date} ${time}`)
        const convertedDate = convertedDateLocal.utc().format('YYYY-MM-DD HH:mm:ss')
        return convertedDate
     }
    const listLangReformat = () => {
      return {
        links: listLang?.map((lg) => ({
          link: lg.link,
          lang: lg.lang,
        })),
      };
    };



    const onSubmit = async () => {
        let langs = [...values.langs]
        handleOthersCheckbox(langs)
        await (!values.accessType) && setValues({...values, "accessType": ""})
        // convert date
        const startTime = values.startDate && values.startHour ? convertDateToUtcFormat(values.startDate.format("YYYY-MM-DD"),values.startHour.format("HH:mm:ss")) + "+00:00" : "";
        const stopTime = values.startDate && values.finalHour ? convertDateToUtcFormat(values.startDate.format("YYYY-MM-DD"),values.finalHour.format("HH:mm:ss")) + "+00:00" : "";
        await setVariables({
          id: eventID,
          empParametersCanalForm: {
            richMediaPresentation: values.richMediaPresentation
              ? values.richMediaPresentation
              : false,
            organizerName: values.organizerName ? values.organizerName : "",
            organizerEmail: values.organizerEmail ? values.organizerEmail : "",
            smsCount: values.smsCount ? values.smsCount : "",
            accessType: values.accessType ? values.accessType : "",
            comment: values.comment ? values.comment : "",
            categoryId: values.categoryId ? values.categoryId : null,
            isAuto: values.isAuto,
            StartRecord: values.StartRecord,
            isDvr: values.isDvr,
            isYoutube:values.isYoutube,
            video: {
              title: values.title ? values.title : "",
              copyright: values.copyright ? values.copyright : "",
              keyWords: values.keyWords ? values.keyWords : "",
              isVisibleOnInternet: values.isVisibleOnInternet
                ? values.isVisibleOnInternet
                : false,
              isSecuredOnInternet: values.isSecuredOnInternet
                ? values.isSecuredOnInternet
                : false,
              passwordInternet: values.passwordInternet
                ? values.passwordInternet
                : "",
              logo: uploadLogoUrl,
              startDate: startTime.replace(" ", "T"),
              endDate: stopTime.replace(" ", "T"),
              downloadable: values.downloadable ? values.downloadable : false,
              description: values.description ? values.description : "",
            },
            langs: langs ? langs : [],
            themeId: values.themeId ? values.themeId : null,
            stationID: values.stationID ? values.stationID : null,
            vodRecord: values.vodRecord ? values.vodRecord : false,
            timeStart: startTime.replace(" ", "T"),
            timeStop: stopTime.replace(" ", "T"),
            youtubeLinks: listLangReformat()
          },
        });
        callback()
    };

    const onAnnule = async (event) => {
        await Modal.confirm({
            title: t("Translate.CancelOperation"),
            content: eventID ? t("Translate.CancelModification") : t("Translate.CancelCreation"),
            icon: <ExclamationCircleOutlined />,
            okText: "confirmer",
            okType: 'danger',
            cancelText: "annuler",
            onOk: async () => {
                event.preventDefault()
                history.push({ pathname: '/' + addPath })
            }
        })
        callback()
    }

    return {
        onChange,
        onChangeKeywords,
        onChange_select,
        onChange_datePicker,
        onChange_dateTime_fin,
        onChange_dateTime_debut,
        onChange_checkbox,
        onChange_choose_language,
        onSubmit,
        onAnnule,
        disablePastDate,
        startGetDisabledHours,
        FinalGetDisabledHours,
        startGetDisabledMinutes,
        finalGetDisabledMinutes,
        handleLangSelect,
        values,
        variables
    };
};
