export const TYPES = {
    START_EVENT_SUCCESS : "START_EVENT_SUCCESS", 
    START_EVENT_FAILURE : "START_EVENT_FAILURE",
    STOP_EVENT_SUCCESS : "STOP_EVENT_SUCCESS", 
    STOP_EVENT_FAILURE : "STOP_EVENT_FAILURE",
    STOP_EVENT_FLOW_SUCCESS : "STOP_EVENT_FLOW_SUCCESS", 
    STOP_EVENT_FLOW_FAILURE : "STOP_EVENT_FLOW_FAILURE",
    CURRENT_STATUS : "CURRENT_STATUS", 


}
