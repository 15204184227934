import React, { useEffect, useState } from "react";
import { Table, Alert, Button } from "antd";
import { Row, Col } from "antd";
import UseActionMenu from "./ActionMenuEvent";
import { useSelector } from "react-redux";
import { GraphQLFetchData } from "../page/utils/graphQLFetchData";
import { useDispatch } from "react-redux";
import { setConfirmMultiDelete, setDeleteMultiEvents, setMultiDelete, setPaginationProps,  setshowDivsConditions } from "../redux/actions";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "./getWindowDimensions";
import ModalConfirmation from "./ModalConfirmation";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
var obj;

function useDataTableEvent(
  { columns, updateEntityPath, dataSource },
  fetch_element_selected,
  fetch_id_row,
  deleteOneRow,
  recoverOneRow,
  showModal,
  takeidVideo,
  paginationSize,
  paginationTotal,
  permission_row_selected,
  delete_lang_permission,
  embed_code_permission,
  refetchEvents,
  GetEvent,
  GetTrashedEvents,
) {
  const { deleteRow, moveToTrashMutation,deleteMutaion,restoreMutation } = GraphQLFetchData();
  const [selectedRowKeys] = useState([]);
  const [selectedRow] = useState(null);

  const [selectMultiEvent ,setSelectMultiEvent] =useState([])

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  const [stopDelete, setStopDelete] = useState(false);
  // state pour stocker la valeur de status.
  const valuePagination = useSelector((state) => state.reducer.pagination);
  const paginationProps = useSelector((state) => state.reducer.pagination);
  const condition = useSelector((state) => state.reducer.showDivsCondition);
  const ListVideos = useSelector((state) => state.reducer.ListVideos);
  const searchParams = new URLSearchParams(window.location.search);
  const evenement = searchParams.get("filter"); // language params

  const onConfirmMultiDelete  = useSelector((state) => state.reducer.onConfirmMultiDelete )


  const { event_module } = useSelector(
    (state) => state.ReducerUserData.modules
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showModaltabletoaction = (e, x) => {
    showModal(e, x);
  };

  const deleteRowfromTabletoAction = (e) => {
    deleteOneRow(e);
  };

  const alert_delete_one_row = (e) => {
    dispatch( 
      setshowDivsConditions({
        showDivsConditionsName: "deleteOneRow",
        showDivsConditionsValue: e,
      })
    );
  };

  const  onDeleteMulti = async ()=>{
  
    try {
      const idList = selectMultiEvent?.map(function(e){return e?.toString()});

      await dispatch(
        setshowDivsConditions({ showDivsConditionsName: "deleteOneRow", showDivsConditionsValue: false,})
        );
      await dispatch(
        setshowDivsConditions({showDivsConditionsName: "rowId",showDivsConditionsValue: idList})
        );


        let filterListVid = []
        let deletedItems= []
        let notDeleted = []
       
      obj = setTimeout(async () => {  
        await dispatch(
          setshowDivsConditions({showDivsConditionsName: "deleteOneRow",showDivsConditionsValue: true,}
          ) 
        );
     
     
       if(evenement == "trash" ){           
         const deleteResponse =  await deleteMutaion({ variables: { idList: idList } }) 
         const {data: { deleteEventOrganizers }} = await  deleteResponse;
         deletedItems = deleteEventOrganizers.deleteditems || [];

         await GetTrashedEvents(); 

        }else{
          const deleteResponse =  await moveToTrashMutation({ variables: { idList: idList } })
          const { data: { trashEventOrganizers }} =  deleteResponse;
           deletedItems = trashEventOrganizers.deleteditems || [];

         await GetEvent();

       
      }
 

       filterListVid = ListVideos.data.filter((item) => {
        return !deletedItems.includes(item.id);
      });
       notDeleted = condition.rowId.filter((item) => {
        return !deletedItems.includes(item);
      });
 
      if (filterListVid?.length === 0) {
         dispatch(
          setPaginationProps({
            PaginationPropsNameChange: "current",
            PaginationPropsValueChange:
              paginationProps.current !== 1
                ? paginationProps.current - 1
                : 1,
          })
        );
      }
    }, 3000)

    dispatch(  
      setshowDivsConditions({  showDivsConditionsName: "rowId",   showDivsConditionsValue: [...notDeleted],})
     ); 
    setSelectMultiEvent ([])   
    dispatch(setConfirmMultiDelete(false))  

  }catch(error){
  
  }
 }

 

     
  const onCancelDeleteMulti = async ()=>{
    dispatch(setConfirmMultiDelete(false)) 
  }
  
  const handleRestore=async(record)=>{
    let filterListVid = [];  
     await restoreMutation({ variables: { idList: [record.id] } }).then(
      async (res) => {
        const {
          data: { restoreEventOrganizers },
        } = await res;
        let affectedItems = restoreEventOrganizers.affecteditems || [];

        filterListVid = ListVideos.data.filter((item) => {
          return !affectedItems.includes(item.id);
        });
        let notAffected= condition.rowId.filter((item) => {
          return !affectedItems.includes(item);
        });

        if (filterListVid.length === 0) {
          await dispatch(
            setPaginationProps({
              PaginationPropsNameChange: "current",
              PaginationPropsValueChange:
                paginationProps.current !== 1
                  ? paginationProps.current - 1
                  : 1,
            })
          );
        }
         await GetTrashedEvents();
        await dispatch(
          setshowDivsConditions({
            showDivsConditionsName: "rowId",
            showDivsConditionsValue: [...notAffected],
          })
        );})}
    
 
  const handleDelete = async (record) => {
    let filterListVid = [];
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "deleteOneRow",
        showDivsConditionsValue: false,
      })
    );
    // Time out to Run API Delete
    obj = setTimeout(async () => {
      await dispatch(
        setshowDivsConditions({
          showDivsConditionsName: "deleteOneRow",
          showDivsConditionsValue: true,
        })
      );
      evenement == "trash" ? await deleteMutaion({ variables: { idList: [record.id] } }).then(
        async (res) => {
          const {
            data: { deleteEventOrganizers },
          } = await res;
          let deletedItems = deleteEventOrganizers.deleteditems || [];

          filterListVid = ListVideos.data.filter((item) => {
            return !deletedItems.includes(item.id);
          });
          let notDeleted = condition.rowId.filter((item) => {
            return !deletedItems.includes(item);
          });

          if (filterListVid.length === 0) {
            await dispatch(
              setPaginationProps({
                PaginationPropsNameChange: "current",
                PaginationPropsValueChange:
                  paginationProps.current !== 1
                    ? paginationProps.current - 1
                    : 1,
              })
            );
          }
          await GetTrashedEvents();
          await dispatch(
            setshowDivsConditions({
              showDivsConditionsName: "rowId",
              showDivsConditionsValue: [...notDeleted],
            })
          );
        }
      ) : 
      await moveToTrashMutation({ variables: { idList: [record.id] } }).then(
        async (res) => {
          const {
            data: { trashEventOrganizers },
          } = await res;
          let deletedItems = trashEventOrganizers.deleteditems || [];

          filterListVid = ListVideos.data.filter((item) => {
            return !deletedItems.includes(item.id);
          });
          let notDeleted = condition.rowId.filter((item) => {
            return !deletedItems.includes(item);
          });

          if (filterListVid.length === 0) {
            await dispatch(
              setPaginationProps({
                PaginationPropsNameChange: "current",
                PaginationPropsValueChange:
                  paginationProps.current !== 1
                    ? paginationProps.current - 1
                    : 1,
              })
            );
          }
          await GetEvent();
          await dispatch(
            setshowDivsConditions({
              showDivsConditionsName: "rowId",
              showDivsConditionsValue: [...notDeleted],
            })
          );
        }
      );
    }, 3000);
  };
  // Fonction Clear Time out
  const handleDeleteOneRow = (e) => {
    clearTimeout(obj);
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "deleteOneRow",
        showDivsConditionsValue: true,
      })
    );
    setTimeout(() => {
      setStopDelete(false);
    }, 1000);
  };

  const takeIdVideoTabletoaction = (e) => {
    takeidVideo(e);
  };
  const permission_delete_lang = (e) => {
    delete_lang_permission(e);
  };
  const permission_embedCode_lang = (e) => {
    embed_code_permission(e);
  };
  const updatedColumns = [
    ...columns,
    {
      title: "Actions",
      key: "action",
      className: "column_action",
      render: (action, record) => {
        return (
          <div className="div-action-table">
            <UseActionMenu
              permission_delete_lang={permission_delete_lang}
              permission_embedCode_lang={permission_embedCode_lang}
              takeIdVideoTabletoaction={takeIdVideoTabletoaction}
              showModaltabletoaction={showModaltabletoaction}
              stopDelete={stopDelete}
              alert_delete_one_row={alert_delete_one_row}
              deleteRowfromTabletoAction={deleteRowfromTabletoAction}
              record={record}
              handleDelete={handleDelete}
              selectedRow={selectedRow}
              selectedRowKeys={selectedRowKeys}
              updateEntityPath={updateEntityPath}
              refetchEvents={refetchEvents}
              handleRestore={handleRestore}
            ></UseActionMenu>
          </div>
        );
      },
    },
  ];
  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    if (valuePagination.order !== sorter.order && sorter.order) {
      paginationProps["order"] = sorter.order;
    }
    //dispatch current page
    if (valuePagination.current !== pagination.current) {
      valuePagination["current"] = pagination.current;

      if (document.querySelector(".ShowEvent"))
        document.querySelector(".ShowEvent").scrollIntoView();
    }
    //dispatch size page
    if (
      valuePagination.pageSize !== pagination.pageSize &&
      pagination.pageSize
    ) {
      valuePagination["pageSize"] = pagination.pageSize;
    }
    if (valuePagination.columnKey !== sorter.columnKey && sorter.column) {
      valuePagination["columnKey"] = sorter.column.key;
    }
    dispatch(
      setPaginationProps((paginationProps) => ({
        ...paginationProps,
        ...valuePagination,
      }))
    );
  };

 



useEffect(()=>{
   dispatch(setConfirmMultiDelete(false)) 
   dispatch(setDeleteMultiEvents(selectMultiEvent?.length > 0)) 
  
},[selectMultiEvent])
 
  const [selectionType, setSelectionType] = useState('checkbox');
  const rowSelection = { 
    type: selectionType, 
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectMultiEvent (selectedRowKeys)   
     },
    selectedRowKeys:selectMultiEvent 

   };

   
 

  const {matches} = useWindowDimensions();
  const DataTable = () => (
    

    <>
    

    <Row>
      <Col span={24} className="table_alert">
        {/* Data Table*/}
        <Table
          scroll={{ y: `calc(${!matches ? "100vh": "65vh"} - 120px)` }}
          className="table"
          rowKey={(record) => record.id}
          rowSelection={
      /*       permission_row_selected &&
            event_module?.event_delete_multi_action?.authorized ? */ 
             { 
              ...rowSelection,


            }
   }
          columns={updatedColumns}
          dataSource={dataSource.content}
          onChange={handleTableChange}
          pagination={{
            locale:{ jump_to: t("Translate.go_to_page"),page:''},
            current: valuePagination.current,
            total: dataSource.totalElements,
            showQuickJumper: true,
            showSizeChanger: true,
          }}
        />

        {/* Alert Delete event*/}

        

        {!condition.deleteOneRow ? (
          <div className="div_alert_DataTable">
            <Alert
              id="ant-alert_DataTable"
              message={ 
                condition?.rowId?.length > 1 ?
                t("Message.ElementsSelecteds") : t("Message.ElementSelected")}
              banner
              action={
                <Button
                  disabled={stopDelete}
                  onClick={handleDeleteOneRow}
                  className="btn_annuler"
                  size="small"
                  type="text"
                >
                  {t("Translate.Annuler")}
                </Button>
              }
            />
          </div>
        ) : null}
      </Col>
    </Row>

 
        <ModalConfirmation
          open={onConfirmMultiDelete}
          onConfirm={onDeleteMulti}
          onCancel={onCancelDeleteMulti}
          cancelBtnName={t('Translate.Annuler')}
          confirmBtnName={t('Translate.Valider')}
          title={ 
            <div className="div_modalConfirmationTitle">
            {t("Modal.DeleteConfirmation")}
          </div> }
          content={
            <p className="p_modalConfirmationContent">
            {t("Modal.DelecteConfirmationContent")}     
          </p>
          }
          >
      </ModalConfirmation>  

     
    
</>

  );




  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
    deleteRow,
  };
}

export default useDataTableEvent;
