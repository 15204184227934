import { TYPES } from "./types"
import axios from "axios"
const token = localStorage.getItem('token')
const headers = {
    Authorization: token ?`Bearer ${token}` : "" ,
  }

const url = window.process.env.API_HOST_GLOBAL;
const urlVideo = window.process.env.VIDEO_API;

let path = urlVideo ? urlVideo : url;

export const getUserData = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_USER_DATA_LOADING, payload: true });
  await axios
    .get(`${path}/core/rights/get-rights-by-module`, { headers })
    .then((res) => {
      dispatch({ type: TYPES.GET_USER_DATA_LOADING, payload: false });
      dispatch({ type: TYPES.GET_USER_DATA, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: TYPES.GET_USER_DATA_FAILED });
      dispatch({ type: TYPES.GET_USER_DATA_LOADING, payload: false });
    });
};
