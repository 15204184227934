import {createStore,compose,applyMiddleware, combineReducers} from 'redux';
import {reducer} from './reducer'
import ReducerUserData from '../userData/reducer'

import thunk from 'redux-thunk'

const rootReducers = combineReducers({reducer,ReducerUserData})
const store = createStore(rootReducers,compose(applyMiddleware(thunk)));
// env dev
// const store = createStore(rootReducers,compose(applyMiddleware(thunk),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));

export default store;