import { TYPES } from "./types";

const initState = {
    loading:true,
    unauthorized: false, 
    userData : {},
    roles:[],
    modules:{
        event_module:[]
    }
}

const ReducerUserData = (state=initState,action)=>{
    switch (action.type) {
        case TYPES.GET_USER_DATA:
            return {
                ...state,
                userData:action.payload.userData.user,
                roles:action.payload.userData.roles,
                modules:action.payload.userData.modules,

            }
        case TYPES.GET_USER_DATA_LOADING:
            return {
                ...state,
                loading:action.payload
            }
        case TYPES.GET_USER_DATA_FAILED:
            return {
                ...state,
                unauthorized : true
            }
        default:
            return state
    }
}
export default ReducerUserData
