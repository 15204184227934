import gql from "graphql-tag";

export const graphQL_shema = ()=> {

    const Get_Stations_Themes_Categories = gql`
        query getInfoForm($empty: String!) {
            getInfoForm(empty: $empty) {
                categories{
                    id
                    title
                }
                themes{
                    title
                    id
                }
                stations{
                    id
                    name
                }
            }
        }
    `;

    const getEvent_byID = gql`
      query getEventByID($id: Int!) {
        getEventByID(id: $id) {
          eventOrganizer {
            id
            richMediaPresentation
            urlTitle
            url
            vodRecord
            organizerName
            organizerEmail
            smsCount
            accessType
            comment
            timeStart
            timeStop
            isAuto
            StartRecord
            isDvr
            isYoutube
            stations {
              id
              name
            }
            categorys {
              id
              title
            }
          }
          videoMedia {
            logoName
            video {
              id
              copyright
              title
              keyWords
              logo
              startDate
              endDate
              description
              downloadable
              isVisibleOnInternet

              isSecuredOnInternet
              passwordInternet

              theme {
                id
                title
              }
            }
            langs
            youtubeLinks {
              links {
                lang
                link
              }
            }
          }
        }
      }
    `;

    const CREATE_EVENT = gql`

        mutation($empParametersCanalForm:EventOrganizerForm!) {
            createEventOrganizer(empParametersCanalForm:$empParametersCanalForm){
                code
                message
            }
        }
    `;
    const UPDATE_EVENT = gql`

        mutation($id:Int!$empParametersCanalForm:EventOrganizerForm!) {
            updateEventOrganizer(id:$id,empParametersCanalForm:$empParametersCanalForm){
                code
                message
            }
        }
    `;
    const DELETE_EVENT = gql`
        mutation($idList:[Int!]!) {
            deleteEventOrganizers(idList:$idList){
                deleteditems
                undeleteditems
                code
            }
        }`;
    const START_EVENT = gql`
        mutation($id: Int!, $status: Int!) {
            updateEventOrganizerStatus(id: $id, status: $status){
                code
                message
            }
        }
    `;
    const uploadToS3 = gql`
        mutation ($file:Upload! $subFolder: String!){
            uploadToS3(file:$file subFolder:$subFolder){
                code
                message
            }
        }
    `
    const UPLOAD_FILE = gql`
        mutation ($file:Upload!)
        {uploadLogo(logo:$file)}

    `;
    const Get_Video_Links = gql`
      query ($idVideo: Int!, $lang: String!) {
        getVideoLinks(idVideo: $idVideo, lang: $lang) {
          code
          youtubeLinks {
            links {
              lang
              link
            }
          }
          srtLink
          srtLink2
          auto {
            permaLink {
              permaLink {
                intranet
                internet
              }
              permaLinkCdn {
                intranet
                internet
              }
            }
            embed {
              embedLink {
                intranet
                internet
              }
              embedLinkCdn {
                intranet
                internet
              }
            }
          }
          live {
            permaLink {
              permaLink {
                intranet
                internet
              }
              permaLinkCdn {
                intranet
                internet
              }
            }
            embed {
              embedLink {
                intranet
                internet
              }
              embedLinkCdn {
                intranet
                internet
              }
            }
          }

          video {
            permaLink {
              permaLink {
                intranet
                internet
              }
              permaLinkCdn {
                intranet
                internet
              }
            }
            embed {
              embedLink {
                intranet
                internet
              }
              embedLinkCdn {
                intranet
                internet
              }
            }
          }
        }
      }
    `;
    const DELETE_LANG = gql `
    mutation( $idVideo:Int!,$lang:String!) {
   deleteLang(idVideo:$idVideo,lang:$lang ) {
    code
    message
    }
}
    `;

    const Get_Calendar_Data = gql`
        query($input: NewEventsPagination,$dates: [String!]!) {
            getCalendar(input: $input,dates:$dates) {
                id
                cType
                content
                thumbnailUrl
                status
                date{
                    date
                    isAMomentObject
                }
            }
        }
    `;

    const Get_langues = gql`
        query {
            getLanguages {
                id
                label
                lang
            }
        }
    `;

    const Get_Action = gql`
    query($idUser: Int!){
  getActions(idUser:$idUser){
      superAdmin
      code
      data{
      id
      name
      }
    }
    }
    `;

    const Get_Export_Url = gql`
    query($idVideo: Int!, $lang: String! ){
  getExportUrl(idVideo:$idVideo, lang:$lang){
    code
    auto{
    permaLink{
      permaLink{
          intranet
          internet
      }
      permaLinkCdn{
          intranet
          internet
      }        
    }
    embed{
      embedLink{
          intranet
          internet
      }
      embedLinkCdn{
          intranet
          internet
      }        
    }      
    }
    live{
        permaLink{
          permaLink{
              intranet
              internet
          }
          permaLinkCdn{
              intranet
              internet
          }        
        }
        embed{
          embedLink{
              intranet
              internet
          }
          embedLinkCdn{
              intranet
              internet
          }        
        }      
        }
        video{
            permaLink{
              permaLink{
                  intranet
                  internet
              }
              permaLinkCdn{
                  intranet
                  internet
              }        
            }
            embed{
              embedLink{
                  intranet
                  internet
              }
              embedLinkCdn{
                  intranet
                  internet
              }        
            }      
            }
  }
  }
    `;


    const Get_Export_Excel = gql`
    query($start_date: String!, $end_date: String! ){
    getExcelFile(start_date:$start_date, end_date:$end_date){
    code
    link
  }
  }
    `;


    const GET_EVENTS = gql`
        query($input: NewEventsPagination) {
            getEvents(input: $input) {
                data{
                    id
                    rich_media_presentation
                    timeStart
                    timeStop
                    StartRecord
                    isDvr
                    isYoutube
                    ConferenceKey
                    date
                    isAuto
                    video{
                        id
                        title
                        date
                        is_visible_on_internet
                        is_secured_on_internet
                        
                        start_date
                        end_date
                        duration
                        date
                        logo
                        thumbnail_url

                    }
                    medias{
                        id
                        lang
                    }
                    station{
                        id
                        ip
                        name
                        domain
                        intranetStreamFr
                        intranetStreamEn
                        intranetStreamEs
                        intranetStreamOr
                        description
                      }

                }
                records_total
                records_filtered
                code
            }
        }
    `;

    const Stop_Event = gql`
        mutation($idVideo: Int!, $endDate: String !) {
            stopLiveEvent(idVideo:$idVideo, endDate:$endDate){
                code
                message
            }}
    `;

    const CLIENT_NAME = gql`

        mutation ($clientName: String !){
         automatedEventUpdates(clientName:$clientName){
          code
          message
        }
      }
    `;
    const trashEvent = gql`

    mutation ($idList:[Int!]!){
        trashEventOrganizers(idList:$idList){
        affecteditems
        unaffecteditems
      code
    }
  }
`;
const getTrashedEvents = gql`
        query($input: NewEventsPagination) {
            getEvents(input: $input) {
                data{
                    id
                    rich_media_presentation
                    timeStart
                    timeStop
                    StartRecord
                    isDvr
                    date
                    video{
                        id
                        title
                        date
                        is_visible_on_internet
                        
                        is_secured_on_internet
                       
                        start_date
                        end_date
                        duration
                        date
                        logo
                        thumbnail_url

                    }
                    medias{
                        id
                        lang
                    }
                    station{
                        id
                        ip
                        name
                        domain
                        intranetStreamFr
                        intranetStreamEn
                        intranetStreamEs
                        intranetStreamOr
                        description
                      }

                }
                records_total
                records_filtered
                code,
                __typename
            }
        }
    `;
    const restoreEvent = gql`
    mutation ($idList:[Int!]!){
     restoreEventOrganizers(idList:$idList ){
      code,
      affecteditems,
      unaffecteditems
    }
  }
`;
    return({
        Get_Stations_Themes_Categories,
        CREATE_EVENT,
        getEvent_byID,
        UPDATE_EVENT,
        DELETE_EVENT,
        START_EVENT,
        UPLOAD_FILE,
        Get_Video_Links,
        DELETE_LANG,
        Get_Calendar_Data,
        Get_langues,
        Get_Action ,
        GET_EVENTS,
        Stop_Event,
        CLIENT_NAME,
        Get_Export_Url,
        Get_Export_Excel,
        uploadToS3,
        trashEvent,
        getTrashedEvents,
        restoreEvent
    })
}