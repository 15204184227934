import React from 'react';
import { Link} from 'react-router-dom';

function Default() {
    return(
         <div className="Default_Page">
         <span>404</span>
         <h3>Page Not Found</h3>
         <p>The Page you are looking for doesn't exist or an other error occured. Go to <Link to="/events/list">Home Page</Link></p>
         </div>
    );
}

export default Default;