import React from 'react'
import { Menu } from '@material-ui/core'

function MenuMore({anchor,open,close,menu}) {
  return (
    <Menu anchorEl={anchor} open={open} onClose={close} className='menuAction'>
        <div className="menu">{menu}</div>
        </Menu>
  )
}

export default MenuMore
