export const EventList = () => {

    const FilterVideos = () => {
        return (
            {
                search: "",
                searchFake: "",
                periode: "",
                date: [],
                contributeur: null,
                type: "tous",
                stationID: 0,
                stationIDRedux:0,
                themeId: 0,
                themeIdRedux: 0,
                dateStingValue:"",
                dateString: "",
                themeIdName:"",
                stationIDName:""
            }
        )
    }
    const paginationProps = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
                idDiffusion: null
            }
        )
    }
    const showDivsConditions = () => {
        return ({
            clickDeleteIcon: true,
            deleteOneRow: true,
            elements_selected: 0,
            rowId: [],
            rubDeleteItems: false,
            type_diffusion:"",
            valuecheckboxCDN:false
        })
    }

    const loadingDeleteShowVideo = () => {
        return ({
            loadingDelete: false
        })
    }


    return ({
        paginationProps,
        FilterVideos,
        showDivsConditions,
        loadingDeleteShowVideo
    })

}