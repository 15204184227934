import React, { useState, useEffect } from "react";
import HeaderEvents from "../../components/HeaderEvents";
import useDataTableEvent from "../../components/DataTableEvent";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { graphQL_shema } from "../utils/graphql";
import {useWindowSize} from "../utils/resizeHook"
import {
  Tooltip,
  Button,
  Dropdown,
  Menu,
  Space,
  Spin,
  message,
  Alert,
  Image,
} from "antd";
import {
  AudioFilled,
  DeleteOutlined,
  DownOutlined,
  LinkOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import "../../assests/icomoon/style.css";
// import logo from "../../assests/img_file.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  setshowVideosActions,
  setFilterVideosActions,
  setshowDivsConditions,
  setPaginationProps,
  changeLang,
} from "../../redux/actions";
import moment from "moment";
import { getUserData } from "../../userData/action";
import ModalExport from "./ModalExport";
import LoadingScreen from "../../components/LoadingScreen";

var objalert;

function ShowEvent() {
  const windowSize = useWindowSize()
  const [paginationTotal, setPaginationTotal] = useState(10); //state pour changer les nombres des lignes par page
  const [search, setSearch] = useState(""); // state pour filtrer le tableau// state pour donner les nombres des données récupérer
  const [Loading, setLoading] = useState(false); // state pour loading tableau des événements
  const [recoveronerow, setrecoveronerow] = useState(null); // recover One Rows
  const [visible, setVisible] = useState(false); // show modal Embed Code
  const [takeIdRowlangue] = useState(null); // take ID Row from langue dans la liste des événments

  const [showAlertDeltelang, setshowAlertDeltelang] = useState(false); // show alert to delete lang (UNDO)
  const [cleartimeoutdeletelang, setcleartimeoutdeletelang] = useState(false); // disable annuler alert
  const [recoverLangDeleted, setrecoverLangDeleted] = useState(""); //recover deleted lang
  const [permission_row_selected, set_permission_row_selected] = useState(null); //permission pour la selction de row de table
  const [permissionDeleteLang, setPermissionDeletelang] = useState(false); // permission delete lang
  const [permissionEmbedCodeLang, setpermissionEmbedCodeLang] = useState(false); //permisson Embed Code Lang

  const paginationProps = useSelector((state) => state.reducer.pagination);
  const values = useSelector((state) => state.reducer.ListVideos);
  const condition = useSelector((state) => state.reducer.showDivsCondition);
  const filterVideo = useSelector((state) => state.reducer.FilterVideos);
  const loadingUserData = useSelector(state=> state.ReducerUserData.loading)
  const userUnauthorized = useSelector(state=> state.ReducerUserData.unauthorized)
  const logo = '/noEventImg.jpg';
  const history = useHistory(); // for routing
  const { t } = useTranslation(); // translation
  const searchParams = new URLSearchParams(window.location.search);
  const language = searchParams.get("lang"); // language params
  const evenement = searchParams.get("filter"); // filter params
  const dispatch = useDispatch();
  const status = useSelector((state) => state.reducer.currentStatus);

  useEffect(() => {
    dispatch(changeLang(language));
    dispatch(getUserData());
    // reset search filter
    dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "searchFake",
        FilterVideosValueChange: "",
      })
    )
    dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "search",
        FilterVideosValueChange: "",
      })
    );
    dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "searchById",
        FilterVideosValueChange: 0,
      })
    );
  }, [dispatch]);
  // useEffect to change language from url
const changeLangEvent = (event) => {
  if (event.data?.info === "updateLang") {
    i18next.changeLanguage(event.data.lang)
    localStorage.setItem("lang", event.data.lang)
  }
 }
 
 useEffect(()=>{
 GetEvent() 

 },[status])
  useEffect(() => {
    if (language === "fr") {
      i18next.changeLanguage("fr");
    } else if (language === "en") {
      i18next.changeLanguage("en");
    } else if (language !== ("en" && "fr")) {
      return <Redirect to="/events/list" />;
    }
    window.addEventListener("message",changeLangEvent)
    return ()=> window.removeEventListener("message",changeLang)
  }, [language]);
  // fonction pour changer les nombres des lignes par pages
  const paginationSize = (e) => {
    setPaginationTotal(e);
  };
  // fonction pour compter les lignes sélectionnées de tableau
  const fetch_element_selected = (selected) => {
    //setElement_selected(selected);
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "elements_selected",
        showDivsConditionsValue: selected,
      })
    );
    //setElement_selected(selected);
  };

  // fonction pour prendre l'id de chaque row du tableau
  const fetch_id_row = (idVideo) => {
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "rowId",
        showDivsConditionsValue: idVideo,
      })
    );
  };

  // fonction pour filtrer le tableau des événements
  const searchRow = async (e) => {
    if (e.target.value !=="") {
      const searchKeyword = e.target.value;
      setSearch(searchKeyword);
      const searchIdFilter = /^\d+$/.test(searchKeyword) ? +searchKeyword : 0;
      await  Promise.all([   
         dispatch(
        setshowDivsConditions({
          showDivsConditionsName: "rowId",
          showDivsConditionsValue: [],
        })
      ),
       dispatch(
        setPaginationProps({
          PaginationPropsNameChange: "current",
          PaginationPropsValueChange: 1,
        })
      ),
  
       dispatch(
        setFilterVideosActions({
          FilterVideosNameChange: "search",
          FilterVideosValueChange: searchKeyword,
        })
      ),
       dispatch(
        setFilterVideosActions({
          FilterVideosNameChange: "searchById",
          FilterVideosValueChange: searchIdFilter,
        })
      )])
    }
  };

  // recover Rows deleted
  const recoverDeleteItem = (id) => {
    refetchEvents();
  };

  // handle cancel
  const handleCancel = () => {
    setVisible(false);
  };

  //show Modal function
  const showModal = (e) => {
    setVisible(e);
  };
  // fonction delete one row
  const deleteOneRow = (id) => {
    let items = values.data.filter((item) => {
      return item.id !== id;
    });
    dispatch(setshowVideosActions(items));

    let item_deleted = values.data.filter((item) => {
      return item.id == id;
    });
    setrecoveronerow(item_deleted);
  };

  // recover One Row
  const recoverOneRow = (record) => {
    let videoList = values.data;
    dispatch(setshowVideosActions([...recoveronerow, ...videoList]));
  };

  // routing with history
  const handleAddNew = (record) => {
    let date = record.date
      .split(
        record.date.substring(
          record.date.indexOf("("),
          record.date.indexOf("-") + 1
        )
      )
      .join("")
      .split(")")
      .join("");
    if (moment().isBefore(moment(date, "DD-MM-YYYY HH:mm")))
      history.push({
        pathname:
          language === "fr" || language === "en"
            ? "/events/addevent/" + language
            : "/events/addevent/",
        state: {
          event: record,
          action: "update",
          initialTypeEvent: evenement,
        },
      });
    else refetchEvents();
  };
  //query getVideosLinks for embed Code
  const [GETIDVIDEOS, { data: GetVideoLinks, loading: loadingLinks }] =
    useLazyQuery(
      graphQL_shema().Get_Video_Links,

      {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          if (data.getVideoLinks.code === 200) {
            dispatch({
              type: "SET_SELECTED_YTB",
              payload:
                data?.getVideoLinks?.youtubeLinks?.links?.length > 0
                  ? true
                  : false,
            });
            dispatch({
              type: "SET_LINKS",
              payload: data?.getVideoLinks?.youtubeLinks?.links,
            });
          } else if (GetVideoLinks.getVideoLinks.code === 404) {
            error_embed_code(404);
          }
        },
      }
    );
  // fonction pour prendre l'id Video si on clique sur embed code dans les actions du tableau
  const takeidVideo = (e) => {
    GETIDVIDEOS({ variables: { idVideo: e, lang: "" } });
  };

  const languagesAction = (e, record, type) => {
    e.preventDefault();
  };

  //afficher embed code si on clique sur dropdown langue "Embed Code"
  const handleEmbedCodeLangue = (e, x) => {
    setVisible(true);
    GETIDVIDEOS({ variables: { idVideo: e.idVideo, lang: x } });
  };
  // error delete lang
  const error_embed_code = (code) => {
    const messageERROR = {
      404: `${t("Message.NotDeleteLang")}`,
    };
    message.error({
      content: messageERROR[code],
      duration: 1.5,
    });
  };
  // Delete langue from row table
  const handleDeleteLang = (record, lang) => {
    let deletedlanguage = record.lang.filter((item) => item === lang);
    setrecoverLangDeleted(deletedlanguage);
    values.data.filter(
      (item) =>
        item.id === record.id &&
        item.lang.splice(item.lang.indexOf(deletedlanguage[0]), 1)
    );
    setshowAlertDeltelang(true);
    setTimeout(() => {
      setshowAlertDeltelang(false);
    }, 3000);

    objalert = setTimeout(() => {
      deletelangfromtable({
        variables: { idVideo: record.idVideo, lang: lang },
      });
    }, 3000);
  };

  // clearTimeOut objalert
  const handleclearTimeoutdeletelang = () => {
    clearTimeout(objalert);
    values.data.filter(
      (item) =>
        item.id === takeIdRowlangue && item.lang.push(recoverLangDeleted[0])
    );
    setcleartimeoutdeletelang(true);
    setTimeout(() => {
      setcleartimeoutdeletelang(false);
    }, 3000);
  };

  //succes delete lang
  const success_delete_lang = () => {
    message.success({
      content: `${t("Message.DeleteLang")}`,
      className: "message-event",
      duration: 1.5,
      style: {
        marginTop: "2vh",
      },
    });
  };
  // error delete lang
  const error_delete_lang = (code) => {
    const messageERROR = {
      400: `${t("Message.NotDeleteLang")}`,
      404: `${t("Message.langNotExist")}`,
    };
    message.error({
      content: messageERROR[code],
      duration: 1.5,
    });
  };

  // permission row selection
  const row_selection_permission = (e) => {
    set_permission_row_selected(e);
  };

  // permission delete lang
  const delete_lang_permission = (e) => {
    setPermissionDeletelang(e);
  };
  // permission embed code lang
  const embed_code_permission = (e) => {
    setpermissionEmbedCodeLang(e);
  };
  // UPDATE FUNCTION
  const update_data = (e) => {
    if (e === true) {
      refetchEvents();
    }
  };
  //Query Get EVENT
  const [GetEvent, { refetch: refetchEvents }] = useLazyQuery(
    graphQL_shema().GET_EVENTS,
    {
      variables: {
        input: {
          limit: paginationProps.pageSize,
          offset: (paginationProps.current - 1) * paginationProps.pageSize, //pour laisser la valeur de l'offset s'il y a pagination en 0
          order_dir: paginationProps.order,
          order_column: paginationProps.columnKey,
          search_word: filterVideo.search,
          ...( {id:filterVideo.searchById}),
          status: status,
          station_id: filterVideo.stationIDRedux,
          theme_id: filterVideo.themeIdRedux,
          date: filterVideo.dateString,
          type_diffusion: condition.type_diffusion,
        },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data.getEvents.code === 200 ) {
          let array =
            data.getEvents.data && data.getEvents.data.map((ele) => ele && ele);

          let record_filtred = data.getEvents.records_filtered;

          if (array) {
            dispatch(
              setshowVideosActions({
                data: array.map((row) => ({
                  id: row.id,
                  apercu: [
                    row.video.thumbnail_url,
                    row.rich_media_presentation,
                    row.video.is_visible_on_intranet,
                    row.video.is_secured_on_intranet,
                    row.video.is_visible_on_internet,
                    row.video.is_secured_on_internet,
                  ],
                  secured_on_internet: row.video.is_secured_on_internet,
                  secured_on_intranet: row.video.is_secured_on_intranet,
                  visible_on_internet: row.video.is_visible_on_internet,
                  visible_on_intranet: row.video.is_visible_on_intranet,
                  rich_media: row.rich_media_presentation,
                  lang: row.medias.map((item) => item.lang),
                  idVideo: row.video.id,
                  isYoutube:row.isYoutube,
                  title: row.video.title,
                  langue: row.medias.map((item) => item.lang),
                  date: row.video.date,
                  status:row?.StartRecord,
                  isAuto:row.isAuto,
                  conferenceKey: row.ConferenceKey
                })),
                totalElements: record_filtred,
              })
            );
            //dispatch(changeLang(match.params.language))
          } else {
            if (search  && (!array || !array.length )) {
              message.error({
                content: `${t("Message.SearchText")}`,
                className: "message-event",
                duration: 1.5,
                style: {
                  marginTop: "2vh",
                },
              });
            }
            dispatch(setshowVideosActions([]));
          }
        }
        setLoading(true);
      },
    }
  );
  const [GetTrashedEvents ] = useLazyQuery(
    graphQL_shema().getTrashedEvents,
    {
      variables: {
        input: {
          limit: paginationProps.pageSize,
          offset: (paginationProps.current - 1) * paginationProps.pageSize, //pour laisser la valeur de l'offset s'il y a pagination en 0
          order_dir: paginationProps.order,
          order_column: paginationProps.columnKey,
          search_word: filterVideo.search,
          ...({id:filterVideo.searchById}),
          status:evenement,
          station_id: filterVideo.stationIDRedux,
          theme_id: filterVideo.themeIdRedux,
          date: filterVideo.dateString,
          type_diffusion: condition.type_diffusion,
        },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data.getEvents.code === 200) {
          let array =
            data.getEvents.data && data.getEvents.data.map((ele) => ele && ele);

          let record_filtred = data.getEvents.records_filtered;

          if (array) {
            dispatch(
              setshowVideosActions({
                data: array.map((row) => ({
                  id: row?.id,
                  apercu: [
                    row?.video?.thumbnail_url,
                    row?.rich_media_presentation,
                    row?.video?.is_visible_on_intranet,
                    row?.video?.is_secured_on_intranet,
                    row?.video?.is_visible_on_internet,
                    row?.video?.is_secured_on_internet,
                  ],
                  secured_on_internet: row?.video?.is_secured_on_internet,
                  secured_on_intranet: row?.video?.is_secured_on_intranet,
                  visible_on_internet: row?.video?.is_visible_on_internet,
                  visible_on_intranet: row?.video?.is_visible_on_intranet,
                  rich_media: row?.rich_media_presentation,
                  lang: row?.medias?.map((item) => item.lang),
                  idVideo: row?.video?.id,
                  isYoutube:row?.isYoutube,
                  title: row?.video?.title,
                  langue: row?.medias?.map((item) => item.lang),
                  date: row?.video?.date,
                  status:row?.StartRecord,

                  conferenceKey: row?.ConferenceKey
                })),
                totalElements: record_filtred,
              })
            );
            //dispatch(changeLang(match.params.language))
          } else {
            if (!search && (!array || !array.length)) {
              message.error({
                content: `${t("Message.SearchText")}`,
                className: "message-event",
                duration: 1.5,
                style: {
                  marginTop: "2vh",
                },
              });
            }
            dispatch(setshowVideosActions([]));
          }
        }
        setLoading(true);
      },
    }
  );

  //Query Get Archived EVENT
  const [GetArchivedEvents] = useLazyQuery(
    graphQL_shema().GET_EVENTS,
    {
      variables: {
        input: {
          limit: paginationProps.pageSize,
          offset: (paginationProps.current - 1) * paginationProps.pageSize, //pour laisser la valeur de l'offset s'il y a pagination en 0
          order_dir: paginationProps.order,
          order_column: paginationProps.columnKey,
          search_word: filterVideo.search,
          ...(filterVideo.searchById !== 0 && {id:filterVideo.searchById}),
          status: "archived",
          station_id: filterVideo.stationIDRedux,
          theme_id: filterVideo.themeIdRedux,
          date: filterVideo.dateString,
          type_diffusion: condition.type_diffusion,
        },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data.getEvents.code === 200) {
          let array =
            data.getEvents.data && data.getEvents.data.map((ele) => ele && ele);
          let record_filtred = data.getEvents.records_filtered;

          if (array) {
            dispatch(
              setshowVideosActions({
                data: array.map((row) => ({
                  id: row.id,
                  apercu: [
                    row.video.thumbnail_url,
                    row.rich_media_presentation,
                    row.video.is_visible_on_intranet,
                    row.video.is_secured_on_intranet,
                    row.video.is_visible_on_internet,
                    row.video.is_secured_on_internet,
                  ],
                  secured_on_internet: row.video.is_secured_on_internet,
                  secured_on_intranet: row.video.is_secured_on_intranet,
                  visible_on_internet: row.video.is_visible_on_internet,
                  visible_on_intranet: row.video.is_visible_on_intranet,
                  rich_media: row.rich_media_presentation,
                  lang: row.medias.map((item) => item.lang),
                  idVideo: row.video.id,
                  isYoutube:row.isYoutube,
                  title: row.video.title,
                  langue: row.medias.map((item) => item.lang),
                  date: row.video.date,
                  status:row?.StartRecord,
                  isAuto:row.isAuto,
                  conferenceKey: row.ConferenceKey
                })),
                totalElements: record_filtred,
              })
            );
            //dispatch(changeLang(match.params.language))
          } else {
            if (search && (!array || !array.length)) {
              message.error({
                content: `${t("Message.SearchText")}`,
                className: "message-event",
                duration: 1.5,
                style: {
                  marginTop: "2vh",
                },
              });
            }
            dispatch(setshowVideosActions([]));
          }
        }
        setLoading(true);
      },
    }
  )
  // mutation delete lang from table of event
  const [deletelangfromtable] = useMutation(graphQL_shema().DELETE_LANG, {
    onCompleted: (data) => {
      if (data.deleteLang.code === "200") {
        success_delete_lang();
      } else if (data.deleteLang.code === "400") {
        error_delete_lang(400);
      } else if (data.deleteLang.code === "404") {
        error_delete_lang(404);
      }
    },
  });

  // function pour récuperer les données pour les stations et les thémes
  useQuery(graphQL_shema().Get_Stations_Themes_Categories, {
    variables: { empty: "" },
  });

  //Dropdown menu modal Embed Code
  const menu = (e, record, x) => {
    return (
      <Menu>
        <>
          {permissionDeleteLang && (
            <Menu.Item
              key="1"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteLang(record, x)}
            >
              {t("Action.Supprimer")}
            </Menu.Item>
          )}
          {permissionEmbedCodeLang && (
            <Menu.Item
              key="2"
              icon={<LinkOutlined />}
              onClick={() => handleEmbedCodeLangue(record, x)}
            >
              {t("Action.lienExport")}
            </Menu.Item>
          )}
        </>
      </Menu>
    );
  };
  const renderApercu = {
    title: `${t("showEvent.Aperçu")}`,
    dataIndex: "apercu",
    className: "column_apercu",
    key: "1",
    render: (apercu, record) => {
      return (
        <div className="div_apercu">
          {
            <Image
              className="img-apercu"
              src={apercu[0] || logo}
              preview={{ visible: false }}
              onError={(e) => {
                e.target.src = logo; // Set the default image when the main image fails to load
              }}
            />
          }
          <div className="div_infos_aperçu">
            {apercu && apercu[1] === 1 && (
              <Tooltip title={t("showEvent.RichMedia")}>
                <span className="btn_apercu">
                  <i id="img_log" className="icon-rich_media"></i>
                </span>
              </Tooltip>
            )}
            {apercu && apercu[3] === 1 && (
              <Tooltip title={t("showEvent.IntranetSecured")}>
                <span className="btn_apercu">
                  <i id="img_log" className="icon-Intranet_securise"></i>
                </span>
              </Tooltip>
            )}
            {apercu && apercu[5] === 1 && (
              <Tooltip title={t("showEvent.InternetSecured")}>
                <span className="btn_apercu">
                  <i id="img_log" className="icon-Internet_securise"></i>
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  }
  const columns = [
    {
      title: `${t("showEvent.ID")}`,
      className: "column_Id",
      dataIndex: "id",
      key: "0",
      sortable: false,
    },
    ...(windowSize > 700 ? [renderApercu] : []),
    {
      title: `${t("showEvent.Titre")}`,
      dataIndex: "title",
      className: "column_title",
      key: "2",
      render: (titre, record) => {
        if(record?.title?.length>25)
        return (<Tooltip title={record.title} placement="top"><div className="div_titre">{record.title}</div></Tooltip>)
        else return(<div className="div_titre">{record.title}</div>)
        
    
      },
    },
    {
      title: `${t("showEvent.Langues")}`,
      dataIndex: "lang",
      className: "column_lang",
      key: "3",
      render: (lang, record) => (
        <div className="div_lang_space">
          <Space wrap className="space_dropdown">
            {record.langue &&
              record.langue.map((x, idx) => {
                const country = x.toUpperCase();
                const registeredLanguages = [
                  "FL-1",
                  "FL-2",
                  "EN",
                  "SP",
                  "FR",
                  "RU",
                  "AR",
                  "ZH",
                ];
                return (
                  <Tooltip
                    key={idx}
                    placement="top"
                    title={
                      x === "or"
                        ? t("language.orateur")
                        : country === "AR"
                        ? t("language.AR")
                        : country === "FR"
                        ? t("language.Français")
                        : country === "EN"
                        ? t("language.Anglais")
                        : country === "SP"
                        ? t("language.ES")
                        : country === "RU"
                        ? t("language.RU")
                        : country === "ZH"
                        ? t("language.ZH")
                        : country === "FL-1"
                        ? t("language.FL1")
                        : country === "FL-2"
                        ? t("language.FL2")
                        : t("language.Autres")
                    }
                  >
                    <Dropdown
                      overlay={(e) => menu(e, record, x)}
                      trigger={["click"]}
                    >
                      <Button
                        className="space_orateur"
                        onClick={(e) => languagesAction(e, record, x)}
                      >
                        {x === "or" ? (
                          <AudioFilled className="audio" />
                        ) : country === "AR" ? (
                          <span class={`fi fi-sa`}></span>
                        ) : country === "ZH" ? (
                          <span class={`fi fi-cn`}></span>
                        ) : country === "EN" ? (
                          <span class={`fi fi-us`}></span>
                        ) : country === "SP" ? (
                          <span class={`fi fi-es`}></span>
                        ) : country === "FL-1" ||
                          country === "FL-2" ||
                          country === "others" ||
                          !registeredLanguages.includes(country) ? (
                          <span id="spn_logo" className={"icon-Others"}>
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </span>
                        ) : (
                          <span class={`fi fi-${x.toLowerCase()}`}></span>
                        )}
                        {(permissionDeleteLang || permissionEmbedCodeLang) && (
                          <DownOutlined />
                        )}
                      </Button>
                    </Dropdown>
                  </Tooltip>
                );
              })}
            {(permissionDeleteLang || permissionEmbedCodeLang) && (
              <Tooltip title={t("showEvent.AjouterLangue")}>
                <div
                  className="button-add_language"
                  onClick={() => handleAddNew(record)}
                >
                  <PlusSquareOutlined
                    className="add_icon_lang"
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </Tooltip>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: `${t("showEvent.Date")}`,
      dataIndex: "date",
      className: "column_date",
      key: "4",
      render: (date, record) => {
        return <div className="div_date label">{record.date}</div>;
      },
    },
  ];
  const dataList = values?.data?.map(el=>{
    const inputDateString = el.date
    const [dateString, timeRange] = inputDateString.split(' ');
    const date = moment.utc(dateString, 'DD/MM/YYYY');
    const [startTime, endTime] = timeRange.split('-').map(time => time.trim());
    const startDateTime = moment.utc(`${dateString} ${startTime}`, 'DD/MM/YYYY HH:mm')
    const endDateTime = moment.utc(`${dateString} ${endTime}`, 'DD/MM/YYYY HH:mm')
    const newLocalDate = `${moment(date).local().format('YYYY-MM-DD')} (${moment(startDateTime).local().format('HH:mm')}-${moment(endDateTime).local().format('HH:mm')})`
    return {...el,date:newLocalDate}
  })
  const data = {
    totalElements: values.totalElements,
    content: dataList ? dataList : [],
  };

  const { DataTable } = useDataTableEvent(
    { columns: columns, dataSource: data, updateEntityPath: "update-product" },
    fetch_element_selected,
    fetch_id_row,
    deleteOneRow,
    recoverOneRow,
    showModal,
    takeidVideo,
    paginationSize,
    paginationTotal,
    permission_row_selected,
    delete_lang_permission,
    embed_code_permission,
    refetchEvents,
    GetEvent,
    GetTrashedEvents
    
  );
    if(loadingUserData){
      return <LoadingScreen/>
    }
    if(userUnauthorized){
      return <img src="/notfound.png" alt="unauthorized" className="notfound-img"/>
    }
  return (
    <div className="showEvent">
      {/*Spinning Header and DataTable*/}
      <Spin size="middle" spinning={!Loading}>
        <HeaderEvents
          update_data={update_data}
          row_selection_permission={row_selection_permission}
          recoverDeleteItem={recoverDeleteItem}
          searchRow={searchRow}
          addNewPath="event/addevent"
          GetEvent={GetEvent}
        />
        <DataTable />
      </Spin>
      {/*Spinning Header and DataTable*/}
      <ModalExport
        visible={visible}
        handleCancel={handleCancel}
        GetVideoLinks={GetVideoLinks?.getVideoLinks}
        loadingLinks={loadingLinks}
      />

      {showAlertDeltelang === true ? (
        <div className="div_alert_lang">
          <Alert
            id="ant-alert_lang"
            message={
              condition.elements_selected > 1
                ? `${t("Message.ElementsSelecteds")}`
                : `${t("Message.ElementSelected")}`
            }
            banner
            action={
              <Button
                disabled={cleartimeoutdeletelang}
                onClick={handleclearTimeoutdeletelang}
                className="btn_annuler"
                size="small"
                type="text"
              >
                {" "}
                {t("Translate.Annuler")}
              </Button>
            }
          />
        </div>
      ) : null}
    </div>
  );
}

export default ShowEvent;
