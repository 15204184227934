import './App.css';
import ShowEvent from './page/product/ShowEvents'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import AddEvents from "./page/product/AddEvents";
import React from "react";
import {Calendar_event} from "./page/product/calendar"
import Default from "./components/Default"
import {message} from "antd";
import {useSelector} from "react-redux";
import 'moment/locale/fr';
import {ConfigProvider} from "antd";
import en_US from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";

message.config({
    maxCount: 1,
});

function App() {
    const lang = useSelector((state) => state.reducer.lang)
    return (
        <ConfigProvider locale={lang === "en" ? en_US : frFR}>
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Redirect exact from="/" to="/events/list"/>
                        <Route exact path='/events/list' component={ShowEvent}/>
                        <Route exact path='/events/addevent' component={AddEvents}/>
                        <Route exact path='/events/editevent/:id' component={AddEvents}/>
                        <Route path='/events/calendar-event' component={Calendar_event}/>
                        <Route component={Default}/>
                    </Switch>
                </BrowserRouter>
            </div>
        </ConfigProvider>
    );
}

export default App;
