import {Modal} from "antd"



const ModalConfirmation = ({title, content, open, cancelBtnName ,confirmBtnName ,onCancel ,onConfirm})=>{
    return (<>
              <Modal
                title={title}
                open={open}
                okText={confirmBtnName}
                cancelText={cancelBtnName}
                onCancel={onCancel}
                onOk={onConfirm}>
                  {content}
              </Modal>
           </>)
  }


  export default   ModalConfirmation