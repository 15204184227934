import {graphQL_shema} from "./graphql";
import {setshowDivsConditions, setLoadingDeleteShowVideo,setPaginationProps} from "../../redux/actions";
import {useMutation} from '@apollo/react-hooks';
import {useDispatch, useSelector} from "react-redux";
import {StatusMessage} from "./StatusMessage";
import {message} from "antd";
import {DeleteOutlined} from '@ant-design/icons';

import { Content } from "antd/lib/layout/layout";
import i18n from "../../i18n";
export const GraphQLFetchData = () => {
    let {success_message, error_message} = StatusMessage()
    const dispatch = useDispatch()
    const condition = useSelector((state) => state.reducer.showDivsCondition)
    const ListVideos = useSelector((state) => state.reducer.ListVideos);
    const paginationProps = useSelector((state) => state.reducer.pagination);

    const [deleteRow] = useMutation(graphQL_shema().DELETE_EVENT, {
        variables: {idList: condition.rowId},
        onCompleted: (data) => {
            dispatch(setLoadingDeleteShowVideo({LoadingDeleteName: "loadingDelete", LoadingDeleteValue: false}));
            if (data.deleteEventOrganizers.code === "200") {
                success_message({ successMessage: 'Message.SuppressionEvent', 
                icon: <DeleteOutlined className="IconDeleteMultiple"/>
            });
            } else if (data.deleteEventOrganizers.code === "400") {
                error_message( { errorMessage: 'Message.SomthingNotWorking', code:   400});
                dispatch(setshowDivsConditions({
                    showDivsConditionsName: "clickDeleteIcon",
                    showDivsConditionsValue: true
                }));
            } else if (data.deleteEventOrganizers.code === "404") {
                error_message({ errorMessage: 'Message.EventHasDeleted', code:   400});
                dispatch(setshowDivsConditions({
                    showDivsConditionsName: "clickDeleteIcon",
                    showDivsConditionsValue: true
                }));
            }
        }

    });

    const [restoreMutation ] = useMutation(graphQL_shema().restoreEvent, {
        onCompleted: async (data) => {
          if (data.restoreEventOrganizers.code === "200") {
            message.success(
                { content: i18n.t('Message.RestoreEvent')}
            );
          } else if (data.restoreEventOrganizers.code === "400") {
          } else if (data.restoreEventOrganizers.code === "404") {

          }
        },
      });
    //deletefromtrash
    const [deleteMutaion] = useMutation(graphQL_shema().DELETE_EVENT, {
        onCompleted: (data) => {
            if (data.deleteEventOrganizers.code === "200") { 
                success_message({successMessage:
                    data?.deleteEventOrganizers?.deleteditems?.length === 1 ? 
                    'Message.SuppressionEvent':'Message.SuppressionEvents'                                        ,
                    icon: <DeleteOutlined className="IconDeleteMultiple"/>
                });
            } else if (data.deleteEventOrganizers.code === "400") {
                error_message({ errorMessage: 'Message.SomthingNotWorking', code:   400});
            } else if (data.deleteEventOrganizers.code === "404") {
                error_message({ 
                    error_message: 'Message.EventHasDeleted', 
                    code:   404});
            }
        }

    });
    //deletetotrash
    const [moveToTrashMutation] = useMutation(graphQL_shema().trashEvent, {
        onCompleted: (data) => {
            if (data.trashEventOrganizers.code === "200") {
                success_message({successMessage:                    
                 data?.trashEventOrganizers?.affecteditems?.length === 1 ? 
                  'Message.SuppressionEvent':'Message.SuppressionEvents'                    
                    ,icon: <DeleteOutlined className="IconDeleteMultiple"/>
                });

            } else if (data.trashEventOrganizers.code === "400") {
                error_message({ errorMessage: 'Message.SomthingNotWorking', code:   400});
            } else if (data.trashEventOrganizers.code === "404") {
                error_message({ 
                    error_message: 'Message.EventHasDeleted',
                    code:   404});
            }
        }

    });
    const [uploadToS3] = useMutation(graphQL_shema().uploadToS3,{
        fetchPolicy:  "no-cache",
        onCompleted:(data)=>{
          if(data?.uploadToS3?.code === "200"){
            const filename = data.uploadToS3?.message.split("/")
            dispatch({type:"UPLOAD_LOGO_URL",payload:data.uploadToS3?.message})
            dispatch({type:"UPLOAD_LOGO_THEME",payload:filename[filename.length - 1]})
          }
        },
        onError:(error)=>{
            message.error("upload failed")
        }
    })

    return ({
        deleteRow,
        moveToTrashMutation,
        uploadToS3,
        deleteMutaion,
        restoreMutation
    })
}
