import React from "react";
import {message} from "antd";
import i18n from '../../i18n';
import {useSelector} from "react-redux";    
export const StatusMessage = () => {
const condition =useSelector((state) =>state.showDivsCondition)
    // success message
    const success_message = ({successMessage, icon}) => {
        message.success({
            content: i18n.t(successMessage),
            icon: icon,
            className: 'message-event',
            duration: 2.5,
            style: {
                marginTop: '2vh',
            },
        });
    }
    // error message
    const error_message = ({errorMessage, code}) => {
        message.warning({
            content: `${code}  ${i18n.t(errorMessage)}`,
            duration: 2.5
        });
    }

    return({
        success_message,
        error_message,
    })
}

 