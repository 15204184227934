
import { TYPES } from "./types"
import axios from "axios"
const headersRecord = {
  Authorization: "Basic ZW1wcmVpbnRlLWFjY2Vzcy10b2tlbg==",
}

const urlRecord = window.process.env.EVENT_RECORD_URL;
const clientName = window.process.env.CLIENT_NAME;
const piperEndpoint = window.process.env.PIPER_ENDPOINT;
const apiLive = window.process.env.API_HOST_LIVE;


export const startEventRec = (e) => async (dispatch) => {
  const data = {
    id: e.conferenceKey,
    stream: {
      name: e.conferenceKey,
      variantNames: []
    }
  }

  await axios.post(`${urlRecord}/v1/vhosts/default/apps/unesco:startRecord`, data, {
    headers: headersRecord
  })
    .then(res => {
      dispatch({ type: TYPES.START_EVENT_SUCCESS, payload: e })
    })
    .catch(err => {
      dispatch({ type: TYPES.START_EVENT_FAILURE })

    })
}
export const endEventRec = (e) => async (dispatch) => {
  const data = {
    id: e.conferenceKey,
  }
  await axios.post(`${urlRecord}/v1/vhosts/default/apps/unesco:stopRecord`, data, { headers: headersRecord })
    .then(res => {
      dispatch({ type: TYPES.STOP_EVENT_SUCCESS, payload: e })
    })
    .catch(err => {
      dispatch({ type: TYPES.STOP_EVENT_FAILURE })

    })
}

export const endEventFlow = (e) => async (dispatch) => {
  const data = {
    stream_name: e.conferenceKey,
    "client_name": clientName,
    "bucket_name": clientName,
    "is_event": true,
    "piper_api": piperEndpoint,
    "live_api": "https:" + apiLive
  }
  await axios.post(`${urlRecord}/live/stop`, data)
    .then(res => {
      dispatch({ type: TYPES.STOP_EVENT_FLOW_SUCCESS, payload: e })
    })
    .catch(err => {
      dispatch({ type: TYPES.STOP_EVENT_FLOW_FAILURE })
    })
}
export function setPaginationProps(e) {
  return {
    type: "SET_PaginationProps",
    payload: e,
  }
}

export function setshowVideosActions(e) {

  return {
    type: "SET_ShowVideos",
    payload: e,
  }

}
export function setcurrentStatus(e) {

  return {
    type: "CURRENT_STATUS",
    payload: e,
  }

}

export function setFilterVideosActions(e) {

  return {
    type: "SET_FilterVideos",
    payload: e,
  }


}

export function setshowDivsConditions(e) {
  return {
    type: "SET_showDivsConditions",
    payload: e
  }
}

export const changeLang = (lang) => ({
  type: "CHANGE_LANG",
  payload: lang
})

export function setLoadingDeleteShowVideo(e) {
  return {
    type: "SET_LoadingDeleteShowVideo",
    payload: e
  }
}

export function setConfirmMultiDelete(e){
  return {
    type:"SET_ConfirmMultiDelete",
    payload:e 
  }
 
}

export function setDeleteMultiEvents(e){ 
  return {
   type:"SET_DeleteMultiEvents",
   payload:e 
 }
}

